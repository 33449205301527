import React, { useState, useRef, useContext } from "react";
import { Avatar, Badge, Button, Link, Tooltip, cn } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ComposeContext } from "../../context/compose_tweet_context";

const MessageCard = React.forwardRef(
  (
    {
      avatar,
      message,
      showFeedback,
      attempts = 1,
      currentAttempt = 1,
      status,
      onMessageCopy,
      onAttemptChange,
      onFeedback,
      onAttemptFeedback,
      className,
      messageClassName,
      ...props
    },
    ref
  ) => {
    const messageRef = useRef(null);

    const { setTweet, setAiModal, setAiTweet, setLoading } =
      useContext(ComposeContext);

    const failedMessageClassName =
      message === "failed"
        ? "bg-danger-100/50 border border-danger-100 text-foreground"
        : "";
    const failedMessage = (
      <p>
        Something went wrong, if the issue persists please contact us through
        our help center
      </p>
    );

    const hasFailed = message === "failed";

    return (
      <div {...props} ref={ref} className={cn("flex gap-3", className)}>
        <div className="relative flex-none">
          <Badge
            isOneChar
            color="danger"
            content={
              <Icon
                className="text-background"
                icon="gravity-ui:circle-exclamation-fill"
              />
            }
            isInvisible={!hasFailed}
            placement="bottom-right"
            shape="circle"
          >
            <Avatar src={avatar} />
          </Badge>
        </div>
        <div className="flex w-full flex-col gap-4">
          <div
            className={cn(
              "relative w-full rounded-medium bg-content2 px-4 py-3 min-h-20 text-default-600",
              failedMessageClassName,
              messageClassName
            )}
          >
            <div
              ref={messageRef}
              className={"pr-20 text-small whitespace-pre-line"}
            >
              {hasFailed ? failedMessage : message}
            </div>
            {showFeedback && !hasFailed && (
              <div className="absolute right-2 top-2 flex rounded-full bg-content2 shadow-small">
                <Button
                  isIconOnly
                  radius="full"
                  size="sm"
                  variant="light"
                  onPress={() => {
                    navigator.clipboard.writeText(message);
                    toast.success("Copied to clipboard!", {
                      autoClose: 3000,
                    });
                  }}
                >
                  <Icon
                    className="text-lg text-default-600"
                    icon="gravity-ui:copy"
                  />
                </Button>
                <Button
                  isIconOnly
                  radius="full"
                  size="sm"
                  variant="light"
                  onPress={() => {
                    setTweet(message);
                    setAiTweet("");
                    setAiModal(false);
                    setLoading(true);
                  }}
                >
                  <Icon
                    className="text-lg text-default-600"
                    icon="mingcute:edit-line"
                  />
                </Button>
              </div>
            )}

            {attempts > 1 && !hasFailed && (
              <div className="flex w-full mt-6 items-end justify-end">
                <p className="px-1 text-tiny font-medium text-default-500">
                  {message?.length}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default MessageCard;

MessageCard.displayName = "MessageCard";
