import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FaCheck, FaCirclePlus } from "react-icons/fa6";
import { GetPlans } from "../graphql";
import { useQuery } from "urql";
import { backend_url } from "../config";
import { AuthContext } from "../context/auth_context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Icon } from "@iconify/react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Divider,
  Link,
  Spacer,
  Tab,
  Tabs,
  cn,
} from "@nextui-org/react";

// import { cn } from "../cn";

const PriceTable = ({ currentPlan, trial_end, setIsPricing }) => {
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);

  const [pricingPeriod, setPricingPeriod] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [tmpPlanId, setTmpPlanId] = useState(null);

  const [result] = useQuery({
    query: GetPlans,
  });

  const { data } = result;

  const plans = data?.plan;

  const handlePlan = async (id) => {
    console.log("id", id);
    setTmpPlanId(id);

    setIsSubmit(true);

    const response = await fetch(
      `${backend_url}/stripe/checkout-new-customer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: auth?.data?.id,
          plan_id: id,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("result", result);
    if (result.message === "done") {
      setIsPricing(false);
      toast.success(
        "Your free trial has begun. You have full access to all features for the next 7 days.",
        { autoClose: 5000 }
      );
      navigate("/connect-twitter");
    } else {
      toast.error(`requested error`, { autoClose: 10000 });
    }
    setTmpPlanId(null);
    setIsSubmit(false);
  };

  const handleUpgrade = async (id) => {
    setIsSubmit(true);
    setTmpPlanId(id);

    const response = await fetch(`${backend_url}/stripe/collect-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: auth?.data?.id,
        plan_id: id,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("result", result);
    if (result.message === "done") {
      window.location.href = result?.data;
    } else {
      toast.error(`requested error`, { autoClose: 10000 });
    }
    setTmpPlanId(null);

    setIsSubmit(false);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const frequencies = [
    { key: "monthly", label: "Monthly", priceSuffix: "month" },
    {
      key: "quarterly",
      label: "Pay Quarterly",
      priceSuffix: "month",
    },
    {
      key: "yearly",
      label: "Pay Quarterly",
      priceSuffix: "month",
    },
  ];

  const onFrequencyChange = (selectedKey) => {
    const frequencyIndex = frequencies.findIndex((f) => f.key === selectedKey);

    setPricingPeriod(frequencyIndex + 1);
  };

  return (
    <>
      <div className="flex max-w-6xl flex-col items-center">
        <div className="flex max-w-2xl flex-col text-center">
          <h1 className="font-semibold text-lg text-primary dark:text-white">
            Tweet smarter, engage better, and scale effortlessly with our
            tailored Twitter solutions
          </h1>
          <Spacer y={4} />
          <h2 className="text-xs font-medium text-default-400">
            Got unique needs or just want to chat? Hit up our friendly chat
            support for a custom plan tailored to your Twitter empire dreams!
          </h2>
          {/* <Spacer y={4} />
          {!currentPlan && (
            <div className="flex justify-center">
              <Card
                isBlurred
                className="border-none bg-background/60 dark:bg-default-100/50 max-w-[400px]"
                shadow="sm"
              >
                <CardHeader className="flex gap-3">
                  <Icon icon="carbon:warning-filled" width={24} />
                  <div className="flex flex-col">
                    <p className="text-md">NO CREDIT CARD REQUIRED</p>
                  </div>
                </CardHeader>
                <Divider />
                <CardBody>
                  <p>
                    Enjoy a risk-free trial with full access to all features.
                  </p>
                </CardBody>
                <Divider />
              </Card>
            </div>
          )} */}
        </div>
        <Spacer y={8} />
        <Tabs
          classNames={{
            tab: "data-[hover-unselected=true]:opacity-90",
          }}
          radius="full"
          size="md"
          onSelectionChange={onFrequencyChange}
        >
          <Tab
            key="monthly"
            aria-label="Monthly"
            className="pr-1.5"
            title="Monthly"
          />

          <Tab
            key="quarterly"
            title={
              <div className="flex items-center gap-2">
                <p>Every 6 months</p>
                <Chip color="primary">Save 10%</Chip>
              </div>
            }
          />
          <Tab
            key="yearly"
            title={
              <div className="flex items-center gap-2">
                <p>Yearly</p>
                <Chip color="primary">Save 20%</Chip>
              </div>
            }
          />
        </Tabs>
        <Spacer y={12} />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {plans
            ?.filter(
              (item) =>
                (item?.name !== "only-admins" &&
                  pricingPeriod === 1 &&
                  (item?.name === "pro" ||
                    item?.name === "growth" ||
                    item?.name === "enterprise")) ||
                (pricingPeriod === 2 &&
                  (item?.name === "pro-6-months" ||
                    item?.name === "growth-6-months" ||
                    item?.name === "enterprise-6-months")) ||
                (pricingPeriod === 3 &&
                  (item?.name === "pro-yearly" ||
                    item?.name === "growth-yearly" ||
                    item?.name === "enterprise-yearly"))
            )
            ?.map((plan) => {
              const isCurrentPlan = plan.id === currentPlan;
              const isMostPopular = !currentPlan && plan.most_popular;

              const finalHigh = isCurrentPlan || isMostPopular;
              return (
                <Card
                  key={plan.id}
                  className={cn("relative p-3", {
                    "overflow-visible bg-primary shadow-2xl shadow-primary/20":
                      finalHigh,
                    "!border-medium border-default-100 bg-transparent lg:mt-12":
                      !finalHigh,
                  })}
                  shadow="none"
                >
                  {finalHigh && (
                    <Chip
                      classNames={{
                        base: "absolute -top-3 left-1/2 -translate-x-1/2 bg-primary-foreground shadow-large border-medium border-primary",
                        content: "font-medium text-primary",
                      }}
                      color="primary"
                    >
                      {isCurrentPlan ? "Current Plan" : "Most Popular"}
                    </Chip>
                  )}

                  <CardHeader className="flex flex-col items-start gap-2 pb-6">
                    <div className="flex gap-2 items-center text-tiny">
                      <h2
                        className={cn("text-lg capitalize font-medium", {
                          "text-primary-foreground": finalHigh,
                        })}
                      >
                        {plan.name?.split("-")?.[0]}
                      </h2>
                      {(plan?.name === "growth" ||
                        plan.name === "growth-6-months" ||
                        plan.name === "growth-yearly") && (
                        <Chip color="success" size="sm" className="text-white">
                          50% Discount applied
                        </Chip>
                      )}
                    </div>

                    <p
                      className={cn("text-tiny text-default-500", {
                        "text-primary-foreground/70": finalHigh,
                      })}
                    >
                      {plan.description}
                    </p>
                  </CardHeader>
                  <Divider className="bg-primary-foreground/20" />
                  <CardBody className="justify-between">
                    <div className="flex flex-col gap-8">
                      <div className="flex items-baseline gap-1">
                        {(plan.name === "pro" ||
                          plan.name === "growth" ||
                          plan.name === "enterprise") && (
                          <p className="flex items-baseline gap-1 pt-2">
                            <span
                              className={cn(
                                "inline bg-gradient-to-br from-foreground to-foreground-600 bg-clip-text text-3xl font-semibold leading-7 tracking-tight text-transparent",
                                {
                                  "text-primary-foreground": finalHigh,
                                }
                              )}
                            >
                              {plan.name === "growth" && (
                                <span
                                  className={cn(
                                    "line-through inline text-xl font-medium leading-7 text-default-400 mr-2",
                                    {
                                      "text-primary-foreground": finalHigh,
                                    }
                                  )}
                                >
                                  ${plan.original_price}
                                </span>
                              )}
                              ${plan.price}
                            </span>
                            <span
                              className={cn(
                                "text-xs font-medium text-default-400",
                                {
                                  "text-primary-foreground/50": finalHigh,
                                }
                              )}
                            >
                              /month
                            </span>
                          </p>
                        )}

                        {(plan.name === "pro-6-months" ||
                          plan.name === "growth-6-months" ||
                          plan.name === "enterprise-6-months") && (
                          <p className="flex items-baseline gap-1 pt-2">
                            <span
                              className={cn(
                                "inline bg-gradient-to-br from-foreground to-foreground-600 bg-clip-text text-3xl font-semibold leading-7 tracking-tight text-transparent",
                                {
                                  "text-primary-foreground": finalHigh,
                                }
                              )}
                            >
                              {(plan.name === "pro-6-months" ||
                                plan.name === "growth-6-months" ||
                                plan.name === "enterprise-6-months" ||
                                plan.name === "pro-yearly" ||
                                plan.name === "growth-yearly" ||
                                plan.name === "enterprise-yearly") && (
                                <span
                                  className={cn(
                                    "line-through inline text-xl font-medium leading-7 text-default-400",
                                    {
                                      "text-primary-foreground": finalHigh,
                                    }
                                  )}
                                >
                                  ${plan.original_price}
                                </span>
                              )}{" "}
                              ${plan.price / 6}
                            </span>
                            <span
                              className={cn(
                                "text-xs font-medium text-default-400",
                                {
                                  "text-primary-foreground/50": finalHigh,
                                }
                              )}
                            >
                              /month
                            </span>
                          </p>
                        )}

                        {(plan.name === "pro-yearly" ||
                          plan.name === "growth-yearly" ||
                          plan.name === "enterprise-yearly") && (
                          <p className="flex items-baseline gap-1 pt-2">
                            <span
                              className={cn(
                                "inline bg-gradient-to-br from-foreground to-foreground-600 bg-clip-text text-3xl font-semibold leading-7 tracking-tight text-transparent",
                                {
                                  "text-primary-foreground": finalHigh,
                                }
                              )}
                            >
                              {(plan.name === "pro-6-months" ||
                                plan.name === "growth-6-months" ||
                                plan.name === "enterprise-6-months" ||
                                plan.name === "pro-yearly" ||
                                plan.name === "growth-yearly" ||
                                plan.name === "enterprise-yearly") && (
                                <span
                                  className={cn(
                                    "line-through inline text-2xl font-medium leading-7 text-default-400 dark:text-default-600",
                                    {
                                      "text-primary-foreground": finalHigh,
                                    }
                                  )}
                                >
                                  ${plan.original_price}
                                </span>
                              )}{" "}
                              ${(plan.price / 12).toFixed(0)}
                            </span>
                            <span
                              className={cn(
                                "text-tiny font-medium text-default-400",
                                {
                                  "text-primary-foreground/50": finalHigh,
                                }
                              )}
                            >
                              /month
                            </span>
                          </p>
                        )}
                      </div>
                      {(plan.name === "pro-6-months" ||
                        plan.name === "growth-6-months" ||
                        plan.name === "enterprise-6-months") && (
                        <span
                          className={cn(
                            "text-sm font-medium text-default-500",
                            {
                              "text-primary-foreground/50": finalHigh,
                            }
                          )}
                        >
                          ${plan.price} billed every 6 months
                        </span>
                      )}

                      {(plan.name === "pro-yearly" ||
                        plan.name === "growth-yearly" ||
                        plan.name === "enterprise-yearly") && (
                        <span
                          className={cn(
                            "text-sm font-medium text-default-500",
                            {
                              "text-primary-foreground/50": finalHigh,
                            }
                          )}
                        >
                          ${plan.price} billed annually
                        </span>
                      )}

                      <ul className="flex flex-col gap-2 text-tiny">
                        {plan.features?.map((feature) => (
                          <li key={feature} className="flex items-center gap-2">
                            <Icon
                              className={cn("text-primary", {
                                "text-primary-foreground": finalHigh,
                              })}
                              icon="ci:check"
                              width={20}
                            />

                            <p
                              className={cn("text-default-500", {
                                "text-primary-foreground/70": finalHigh,
                              })}
                            >
                              {feature}
                            </p>
                          </li>
                        ))}

                        {plan?.extra?.length > 0 &&
                          plan?.extra?.map((extra) => (
                            <div key={extra.name}>
                              <div className="flex justify-center my-4">
                                <FaCirclePlus
                                  className={cn("w-6 h-6 dark:text-white", {
                                    "text-white": finalHigh,
                                  })}
                                />
                              </div>
                              <div
                                className={cn(
                                  "text-white bg-purple-500 rounded-md py-1 px-2 flex justify-center",
                                  {
                                    "bg-primary-foreground": finalHigh,
                                    "text-primary": finalHigh,
                                  }
                                )}
                              >
                                {extra.name}
                              </div>
                              <ul className="mt-8 space-y-3 leading-6 text-default-600">
                                {extra.features?.map((feature) => (
                                  <li
                                    key={feature}
                                    className="flex items-center gap-2"
                                  >
                                    <Icon
                                      className={cn(
                                        "text-primary flex-shrink-0",
                                        {
                                          "text-primary-foreground": finalHigh,
                                        }
                                      )}
                                      icon="ci:check"
                                      width={20}
                                    />

                                    <p
                                      className={cn("text-default-500", {
                                        "text-primary-foreground/70": finalHigh,
                                      })}
                                    >
                                      {feature}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      fullWidth
                      className={cn({
                        "bg-primary-foreground font-medium text-primary shadow-sm shadow-default-500/50":
                          finalHigh,
                      })}
                      color="primary"
                      variant="solid"
                      onClick={() => {
                        trial_end || plan?.id
                          ? handleUpgrade(plan?.id, plan?.name)
                          : plan?.id !== currentPlan &&
                            handlePlan(plan?.id, plan?.name);
                      }}
                      isLoading={
                        isSubmit && plan.id === tmpPlanId ? true : false
                      }
                      size="sm"
                    >
                      {/* {trial_end || plan?.id
                        ? "Upgrade"
                        : "Start 7-day free trial"} */}
                      Upgrade
                    </Button>
                  </CardFooter>
                </Card>
              );
            })}
          <Spacer y={4} />
        </div>
      </div>
    </>
  );
};
export default PriceTable;
