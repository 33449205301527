import React, { createContext, useState, useEffect } from "react";
import { hasura_endpoint } from "../config";
import {
  Provider,
  subscriptionExchange,
  cacheExchange,
  fetchExchange,
  Client,
} from "urql";
import "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createClient as createWSClient } from "graphql-ws";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const authF = getAuth();
  const [auth, setAuth] = useState({ loading: true, data: null });
  const [account, setAccount] = useState({ pLoading: true, data: null });

  const subscriptionClient = new createWSClient(`ws://${hasura_endpoint}`, {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${auth?.data?.jwt}`,
      },
    },
  });
  let headers = {};

  if (auth?.data?.jwt) {
    headers = {
      Authorization: `Bearer ${auth?.data?.jwt}`,
    };
  }

  const client = new Client({
    url: `https://${hasura_endpoint}`,

    exchanges: [
      cacheExchange,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" };
          return {
            subscribe(sink) {
              const unsubscribe = subscriptionClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      }),
    ],
    requestPolicy: "network-only",
    fetch: fetch,
    fetchOptions: () => {
      if (!auth) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  // Function to refresh the Firebase ID token
  const refreshIdToken = async () => {
    try {
      const user = authF.currentUser;
      if (user) {
        const refreshedToken = await user.getIdToken(true);
        return refreshedToken;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing ID token:", error);
      return null;
    }
  };

  // useEffect to refresh token periodically
  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      const refreshedToken = await refreshIdToken();
      if (refreshedToken) {
        setAuthData({ ...auth.data, jwt: refreshedToken });
      }
    }, 55 * 60 * 1000); // Refresh every 55 minutes to ensure token validity

    return () => clearInterval(refreshInterval);
  }, [authF, auth.data]);

  const setAuthData = (data) => {
    if (data && data.verified) {
      setAuth({ data });
      if (data && data.verified) {
        localStorage.setItem("authData", JSON.stringify(data));
      }
    } else {
      setAuth({ data: null });
    }
  };

  const setAccountData = (data) => {
    setAccount({ pLoading: false, data: data });
    if (data) {
      localStorage.setItem("twitterAccount", JSON.stringify(data));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("authData")) {
      setAuth({
        loading: false,
        data: JSON.parse(localStorage.getItem("authData")),
      });
    }

    if (localStorage.getItem("twitterAccount")) {
      setAccount({
        data: JSON.parse(localStorage.getItem("twitterAccount")),
      });
    }
  }, []);

  useEffect(() => {
    return onAuthStateChanged(authF, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true);

          console.log(token);
          const idTokenResult = await user.getIdTokenResult();
          // console.log("id", idTokenResult);
          const hasuraClaim =
            idTokenResult.claims["https://hasura.io/jwt/claims"];
          // console.log("hasura", hasuraClaim);
          if (hasuraClaim["x-hasura-default-role"] === "user") {
            setAuthData({
              jwt: token,
              id: user.uid,
              verified: true,
              email: user.email,
              name: user?.displayName,
            });
          } else {
            setAuthData(null);
            setAccountData(null);
          }
        } catch (error) {
          // console.log("error refresh token", error);
        }
      } else {
        setAuthData(null);
        setAccountData(null);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ auth, setAuthData, account, setAccountData }}
    >
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
