import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { client_id, redirect_url, backend_url } from "../config";
import { AuthContext } from "../context/auth_context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Callback() {
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const state = new URLSearchParams(window.location.search).get("state");

    if (auth?.data && code) {
      fetch(`${backend_url}/twitter/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          redirect_url: redirect_url,
          user_id: auth?.data?.id,
          user_token: auth?.data?.jwt,
        }),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          const resAuth = result.data;
          if (resAuth) {
            if (state === "firstTime") {
              navigate("/install-extension");
            } else if (state === "reconnect") {
              navigate("/");
            }
            // localStorage.setItem("token", result.resAuth);
            // window.location.href = "/install-extension";
          } else if (result?.error === "already-connected") {
            toast.error(
              "This account is currently connected by another user.",
              {
                autoClose: 5000,
              }
            );
            setTimeout(() => {
              navigate("/connect-twitter");
            }, 4000);
          } else {
            toast.error("Something went wrong!", {
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate("/connect-twitter");
            }, 4000);
          }
        })
        .catch((error) => console.log("error", error));
    }
    //  else {
    //   window.location.href = "/";
    // }
  }, [auth?.data]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <ToastContainer />
      <div className="text-center mb-4 text-gray-500">
        Connecting Twitter...
      </div>
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-500 border-solid"></div>
    </div>
  );
}

export default Callback;
