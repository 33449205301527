import React from "react";
import { Link } from "react-router-dom";

import { useTheme } from "next-themes";
import { Image } from "@nextui-org/react";
const Logo = ({ collapsed }) => {
  const { theme, resolvedTheme } = useTheme();
  const isDark = theme === "dark" || resolvedTheme === "dark";

  return (
    <div className="my-1">
      <Link to="/">
        {collapsed ? (
          <img
            className="h-10 w-auto m-auto flex items-center"
            src="/tweetlio-logo.png"
            alt=""
          />
        ) : isDark ? (
          <img className="w-auto h-11" src="/logo-white.png" alt="" />
        ) : (
          <img className="w-auto h-11" src="/logo-black.png" alt="" />
        )}
        {/* {isDark ? (
          <Image src="/logo-white.png" alt="Dark Mode Logo" width={200} />
        ) : (
          <Image src="/logo-black.png" alt="Light Mode Logo" width={200} />
        )} */}
      </Link>
    </div>
  );
};
export default Logo;
