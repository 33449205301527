import React, { useState } from "react";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";

import {
  Switch,
  cn,
  Chip,
  ScrollShadow,
  Textarea,
  Select,
  SelectItem,
  Input,
} from "@nextui-org/react";

const AdvancedOptions = ({
  setIsThreadDelay,
  isThreadDelay,
  selectedThreadDelay,
  setSelectedThreadDelay,
  isAutoRetweet,
  setIsAutoRetweet,
  selectedRetweetInterval,
  setSelectedRetweetInterval,
  selectedRetweetTimes,
  setSelectedRetweetTimes,
  isAutoPlug,
  setIsAutoPlug,
  plugLikes,
  setPlugLikes,
  plugMessage,
  setPlugMessage,
}) => {
  return (
    <>
      <Chip color="primary" variant="light" size="sm">
        These settings will affect this tweet only
      </Chip>
      <div className="overflow-y-auto">
        <Switch
          onClick={() => setIsAutoRetweet(!isAutoRetweet)}
          classNames={{
            base: cn(
              "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
              "justify-between cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
              "data-[selected=true]:border-primary"
            ),
            wrapper: "p-0 h-3 overflow-visible",
            thumb: cn(
              "w-4 h-4 border-2 shadow-lg",
              "group-data-[hover=true]:border-primary",
              //selected
              "group-data-[selected=true]:ml-8",
              // pressed
              "group-data-[pressed=true]:w-7",
              "group-data-[selected]:group-data-[pressed]:ml-4"
            ),
          }}
        >
          <div className="flex flex-col gap-1">
            <p className="text-small">Auto retweet</p>
            <p className="text-tiny text-default-400">instantly share tweets</p>
          </div>
        </Switch>
        {isAutoRetweet && (
          <div className="my-2 flex flex-col justify-between">
            <div className="flex items-center">
              <div className="text-default-600 text-tiny w-32">Interval</div>
              <Select
                variant="underlined"
                className="max-w-xs"
                label="Select"
                selectedKeys={[selectedRetweetInterval]}
                onChange={(e) => setSelectedRetweetInterval(e.target.value)}
              >
                {[
                  ...Array.from({ length: 24 }, (v, k) => (
                    <SelectItem key={`${k + 1}`}>{`${k + 1} hour${
                      k + 1 > 1 ? "s" : ""
                    }`}</SelectItem>
                  )),
                ]}
              </Select>
              {/* <SelectSearch
                options={[
                  ...Array.from({ length: 24 }, (v, k) => ({
                    name: `${k + 1} hour${k + 1 > 1 ? "s" : ""}`,
                    value: `${k + 1}`,
                  })),
                ]}
                value={selectedRetweetInterval}
                search={true}
                onChange={(e) => setSelectedRetweetInterval(e)}
              /> */}
            </div>
            <div className="flex items-center mt-2">
              <div className="text-default-600 w-32 text-tiny"># of times</div>
              <Select
                variant="underlined"
                className="max-w-xs"
                selectedKeys={[selectedRetweetTimes]}
                onChange={(e) => setSelectedRetweetTimes(e.target.value)}
              >
                <SelectItem key="1">1 time</SelectItem>
                <SelectItem key="2">2 time</SelectItem>
                <SelectItem key="3">3 time</SelectItem>
              </Select>
            </div>
          </div>
        )}
        <Switch
          classNames={{
            base: cn(
              "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
              "justify-between cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
              "data-[selected=true]:border-primary"
            ),
            wrapper: "p-0 h-3 overflow-visible",
            thumb: cn(
              "w-4 h-4 border-2 shadow-lg",
              "group-data-[hover=true]:border-primary",
              //selected
              "group-data-[selected=true]:ml-8",
              // pressed
              "group-data-[pressed=true]:w-7",
              "group-data-[selected]:group-data-[pressed]:ml-4"
            ),
          }}
          onClick={() => setIsAutoPlug(!isAutoPlug)}
        >
          <div className="flex flex-col gap-1">
            <p className="text-small">Auto plug</p>
            <p className="text-tiny text-default-400">power up effortlessly</p>
          </div>
        </Switch>
        {isAutoPlug && (
          <div className="mt-2 flex flex-col justify-between">
            <div className="flex items-center">
              <Input
                type="number"
                label="Likes"
                onChange={(e) => setPlugLikes(e.target.value)}
                value={plugLikes}
                className="w-16"
              />
              {/* <input
                type="text"
                className="w-1/2 rounded-md border border-gray-300 py-2 px-3 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                onChange={(e) => setPlugLikes(e.target.value)}
                value={plugLikes}
              /> */}
            </div>
            <div className="flex items-center mt-2">
              <Textarea
                key="plug"
                variant="flat"
                placeholder="Write here"
                className="col-span-12 md:col-span-6 mb-6 md:mb-0"
                value={plugMessage}
                onChange={(e) => setPlugMessage(e.target.value)}
                minRows={10}
                size="sm"
              />
              {/* <textarea
                  className="border text-sm rounded-md border-gray-300 w-full p-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-100"
                  rows="10"
                  placeholder="Write here"
                  value={plugMessage}
                  onChange={(e) => setPlugMessage(e.target.value)}
                ></textarea> */}
            </div>
          </div>
        )}
        <Switch
          classNames={{
            base: cn(
              "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
              "justify-between cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
              "data-[selected=true]:border-primary"
            ),
            wrapper: "p-0 h-3 overflow-visible",
            thumb: cn(
              "w-4 h-4 border-2 shadow-lg",
              "group-data-[hover=true]:border-primary",
              //selected
              "group-data-[selected=true]:ml-8",
              // pressed
              "group-data-[pressed=true]:w-7",
              "group-data-[selected]:group-data-[pressed]:ml-4"
            ),
          }}
          onClick={() => setIsThreadDelay(!isThreadDelay)}
        >
          <div className="flex flex-col gap-1">
            <p className="text-small">Thread delay</p>
            <p className="text-tiny text-default-400">manage task timing</p>
          </div>
        </Switch>
        {isThreadDelay && (
          <div className="mt-3 flex flex-col justify-between">
            <div className="flex items-center">
              <div className="text-default-600 mr-2 ml-4 w-32 text-tiny">
                Delay
              </div>
              <Select
                variant="underlined"
                className="max-w-xs"
                selectedKeys={[selectedThreadDelay]}
                onChange={(e) => setSelectedThreadDelay(e.target.value)}
              >
                <SelectItem key="5s">5 seconds</SelectItem>
                <SelectItem key="10s">10 seconds</SelectItem>
                <SelectItem key="1">1 minute</SelectItem>
                <SelectItem key="2">2 minute</SelectItem>
                <SelectItem key="3">3 minute</SelectItem>
                <SelectItem key="4">4 minute</SelectItem>
                <SelectItem key="5">5 minute</SelectItem>
                <SelectItem key="6">6 minute</SelectItem>
                <SelectItem key="7">7 minute</SelectItem>
                <SelectItem key="8">8 minute</SelectItem>
                <SelectItem key="9">9 minute</SelectItem>
                <SelectItem key="10">10 minute</SelectItem>
                <SelectItem key="30">30 minute</SelectItem>
              </Select>
              {/* <SelectSearch
                options={[
                  {
                    name: "5 seconds",
                    value: "5s",
                  },
                  {
                    name: "10 seconds",
                    value: "10s",
                  },
                  {
                    name: "1 minute",
                    value: "1",
                  },
                  {
                    name: "2 minutes",
                    value: "2",
                  },
                  {
                    name: "3 minutes",
                    value: "3",
                  },
                  {
                    name: "4 minutes",
                    value: "4",
                  },
                  {
                    name: "5 minutes",
                    value: "5",
                  },
                  {
                    name: "6 minutes",
                    value: "6",
                  },
                  {
                    name: "7 minutes",
                    value: "7",
                  },
                  {
                    name: "8 minutes",
                    value: "8",
                  },
                  {
                    name: "9 minutes",
                    value: "9",
                  },
                  {
                    name: "10 minutes",
                    value: "10",
                  },
                  {
                    name: "30 minutes",
                    value: "30",
                  },
                ]}
                value={selectedThreadDelay}
                search={true}
                onChange={(e) => setSelectedThreadDelay(e)}
              /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default AdvancedOptions;
