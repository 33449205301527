import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/auth_context";
import ComposeProvider from "./context/compose_tweet_context";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";
import ThemedSuspense from "./components/themed_suspense";
import Callback from "./pages/Callback";

import packageJson from "../package.json";

const ConnectTwitter = lazy(() => import("./pages/ConnectTwitter"));
const Onboarding = lazy(() => import("./pages/Onboarding"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const SignupSuccess = lazy(() => import("./pages/SignupSuccess"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const InstallExtension = lazy(() => import("./pages/InstallExtension"));
const Page404 = lazy(() => import("./pages/404"));

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const response = await fetch("/meta.json", { cache: "no-store" });
        if (!response.ok) {
          throw new Error("Failed to fetch meta.json");
        }
        const meta = await response.json();
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const lastVersion = localStorage.getItem("lastVersion");

        if (lastVersion && lastVersion === latestVersion) {
          console.log("Already reloaded for this version.");
          return;
        }

        if (semverGreaterThan(latestVersion, currentVersion)) {
          console.log(`New version available - ${latestVersion}. Reloading...`);

          await caches
            .keys()
            .then((names) =>
              Promise.all(names.map((name) => caches.delete(name)))
            );

          localStorage.setItem("lastVersion", latestVersion);
          window.location.reload();
        } else {
          console.log(`Current version is up-to-date - ${latestVersion}.`);
        }
      } catch (error) {
        console.error("Error fetching meta.json:", error);
      }
    };

    checkForUpdates();
  }, []);

  // useEffect(() => {
  //   const checkForUpdates = async () => {
  //     try {
  //       const response = await fetch("/meta.json");
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch meta.json");
  //       }
  //       const meta = await response.json();
  //       const latestVersion = meta.version;
  //       const currentVersion = global.appVersion;

  //       if (semverGreaterThan(latestVersion, currentVersion)) {
  //         console.log(`New version available - ${latestVersion}. Reloading...`);
  //         caches
  //           .keys()
  //           .then((names) =>
  //             Promise.all(names.map((name) => caches.delete(name)))
  //           );
  //         window.location.reload();
  //       } else {
  //         console.log(`Current version is up-to-date - ${latestVersion}.`);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching meta.json:", error);
  //     }
  //   };

  //   checkForUpdates();
  // }, []);

  return (
    <AuthProvider>
      <ComposeProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <div className="App">
            <Router>
              <Routes>
                {/* <Route path="/create-account" element={<CreateAccount />} /> */}
                <Route path="/login" element={<Login />} />
                {/* <Route path="/signup-success" element={<SignupSuccess />} /> */}
                {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                <Route
                  path="/install-extension"
                  element={<InstallExtension />}
                />
                {/* <Route path="/connect-twitter" element={<ConnectTwitter />} /> */}
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/callback" element={<Callback />} />

                {routes.map((route) => (
                  <Route
                    key={route.path} // Use a unique key if possible
                    path={route.path}
                    element={
                      <PrivateRoute
                        path={route.path}
                        children={route.component}
                        title={route.title}
                      />
                    }
                  />
                ))}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Router>
          </div>
        </Suspense>
      </ComposeProvider>
    </AuthProvider>
  );
}

export default App;
