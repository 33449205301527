import { useRef, useContext, useState } from "react";

import { AuthContext } from "../context/auth_context";
import { backend_url } from "../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PriceTable from "./price_table";
import PricingModal from "./pricing_modal";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";

import { getAuth, signOut } from "firebase/auth";

function UpgradeModal({ isUpgrade, setIsUpgrade }) {
  const authC = getAuth();
  const cancelButtonRef = useRef(null);
  const { auth, setAuthData, setAccountData } = useContext(AuthContext);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPricing, setIsPricing] = useState(false);

  const handleUpgrade = async () => {
    setIsSubmit(true);

    const response = await fetch(`${backend_url}/stripe/collect-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: auth?.data?.id,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("result", result);
    if (result.message === "done") {
      window.location.href = result?.data;
    } else {
      toast.error(`requested error`, { autoClose: 10000 });
    }
    setIsSubmit(false);
  };

  return (
    <>
      {isPricing && <PricingModal isPricing={isPricing} trial_end={true} />}

      <Modal
        backdrop="blur"
        isOpen={isUpgrade}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Time to Upgrade
              </ModalHeader>
              <ModalBody>
                <p>
                  Your trial period has ended. To continue using our service and
                  access all features, please consider upgrading your plan.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onClick={() => {
                    localStorage.removeItem("authData");
                    localStorage.removeItem("twitterAccount");
                    setAuthData(null);
                    setAccountData(null);
                    signOut(authC);
                  }}
                >
                  Logout
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsUpgrade(false);
                    setIsPricing(true);
                  }}
                >
                  Upgrade Now
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* <Transition.Root show={isUpgrade} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="relative z-50 w-full max-w-lg p-6 my-8 overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-[700px]">
              <div className="mt-4 space-y-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Time to Upgrade
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Your trial period has ended. To continue using our service
                    and access all features, please consider upgrading your
                    plan.
                  </p>
                </div>
                <div className="mt-8 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none min-w-32"
                    onClick={() => {
                      // Handle upgrade logic here
                      // handleUpgrade(); // Close the modal after upgrading
                      setIsUpgrade(false);
                      setIsPricing(true);
                    }}
                  >
                    {isSubmit ? <LoaderCircleIcon /> : "Upgrade Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
}

export default UpgradeModal;
