import { Fragment, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth_context";

import PriceTable from "./price_table";

function PricingModal({ isPricing, setIsPricing, trial_end }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isPricing} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="relative z-50 w-full max-w-6xl p-6 my-8 overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-[700px]">
            <div className="mt-4 space-y-6">
              <PriceTable trial_end={trial_end} setIsPricing={setIsPricing} />
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PricingModal;
