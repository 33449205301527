// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app";

initializeApp({
  apiKey: "AIzaSyCW_tIT2yaSutQsGyhUf11uef1XdYd17CY",
  authDomain: "tweetlio.firebaseapp.com",
  projectId: "tweetlio",
});

export {};
