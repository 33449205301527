import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../context/auth_context";

import { backend_url } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Avatar,
  Spacer,
  Code,
  ScrollShadow,
  cn,
} from "@nextui-org/react";

import { Icon } from "@iconify/react";

const MediaUploadModal = ({
  isUploadModal,
  setIsUploadModal,
  handleFileUpload,
  fileInputRef,
}) => {
  return (
    <>
      <Modal
        isOpen={isUploadModal}
        onOpenChange={(open) => setIsUploadModal(open)}
        size="lg"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex items-center gap-2 text-medium">
                  Add an image, video or document
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="flex items-center justify-center w-full mb-4">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <Icon
                        icon="f7:cloud-upload"
                        width={30}
                        className="text-default-500"
                      />
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default MediaUploadModal;
