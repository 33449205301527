import { useRef, useContext, useState } from "react";

import { AuthContext } from "../context/auth_context";
import { backend_url } from "../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PriceTable from "./price_table";
import PricingModal from "./pricing_modal";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";

import { getAuth, signOut } from "firebase/auth";

function NewAccountModal({ isNewAccount, setIsNewAccount }) {
  const authC = getAuth();
  const cancelButtonRef = useRef(null);
  const { auth, setAuthData, setAccountData } = useContext(AuthContext);
  const [isConnect, setIsConnect] = useState(false);

  const handleConnectNewAccount = async () => {
    setIsConnect(true);
    const response = await fetch(`${backend_url}/twitter/request-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: "requesttoken",
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("result", result);
    setIsConnect(false);
    window.location.href = result?.data?.authorizeUrl;
  };

  return (
    <>
      <Modal
        isOpen={isNewAccount}
        onOpenChange={(open) => setIsNewAccount(open)}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-medium">
                Add a new account
              </ModalHeader>
              <ModalBody>
                <p className="text-small">
                  Hey there! Just a quick reminder—make sure you're signed in to
                  Twitter with the right account, unless you want to surprise
                  your grandma with some unexpected tweets! 😄
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={onClose}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => handleConnectNewAccount()}
                  isLoading={isConnect}
                  size="sm"
                >
                  Connect
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default NewAccountModal;
