import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";

export function Providers({ children }) {
  return (
    <NextThemesProvider attribute="class" defaultTheme="light">
      <NextUIProvider>{children}</NextUIProvider>
    </NextThemesProvider>
  );
}
