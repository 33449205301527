import React from "react";
import { Spinner } from "@nextui-org/react";

function ThemedSuspense() {
  return (
    <div className="flex justify-center h-screen items-center w-full">
      {/* <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
      <Spinner size="lg" />
    </div>
  );
}

export default ThemedSuspense;
