import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IoClose, IoTimerOutline, IoSend } from "react-icons/io5";

import { ComposeContext } from "../context/compose_tweet_context";
import { AuthContext } from "../context/auth_context";

import "react-toastify/dist/ReactToastify.css";
import { BadgeCheckIcon } from "../icons";
import {
  Avatar,
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  ScrollShadow,
  Spacer,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

function TweetPreview({ close }) {
  const { account } = useContext(AuthContext);

  const [tmpTweet, setTmpTweet] = useState([]);
  const randomNumbersLikeRef = useRef({}); // Ref to store random numbers
  const randomNumbersRetweetRef = useRef({}); // Ref to store retweet random numbers
  const randomNumbersCommentRef = useRef({}); // Ref to store retweet random numbers
  const randomNumbersImpressionRef = useRef({}); // Ref to store retweet random numbers

  const {
    setTweet,
    tweet,
    setAiModal,
    aiTweet,
    setAiTweet,
    loading,
    setLoading,
    retry,
    setRetry,
    setConversation,
    cancel,
    setCancel,
    mediaPreview,
  } = useContext(ComposeContext);

  useEffect(() => {
    const threads = tweet.split(/\n\s*\n\s*\n/);
    setTmpTweet(threads);

    threads.forEach((_, index) => {
      if (!(index in randomNumbersLikeRef.current)) {
        randomNumbersLikeRef.current[index] =
          Math.floor(Math.random() * 1000) + 1;
      }
      if (!(index in randomNumbersRetweetRef.current)) {
        randomNumbersRetweetRef.current[index] =
          Math.floor(Math.random() * 100) + 1;
      }
      if (!(index in randomNumbersCommentRef.current)) {
        randomNumbersCommentRef.current[index] =
          Math.floor(Math.random() * 250) + 1;
      }
      if (!(index in randomNumbersImpressionRef.current)) {
        randomNumbersImpressionRef.current[index] =
          Math.floor(Math.random() * 1500) + 1;
      }
    });
  }, [tweet]);

  return (
    <>
      <Card
        className="border-none max-w-[610px] h-screen bg-gradient-to-r from-violet-300/50 to-purple-100/50 dark:from-violet-300/20 dark:to-purple-100/20"
        isBlurred
        shadow="sm"
      >
        <CardHeader className="flex gap-3">
          <Icon icon="hugeicons:bubble-chat-preview" width={23} />

          <div className="flex items-center justify-between w-full">
            <p className="text-sm">Preview</p>
            <Icon
              icon="solar:close-square-outline"
              width={20}
              className="cursor-pointer"
              onClick={() => close(false)}
            />
          </div>
        </CardHeader>
        <Divider />
        <CardBody>
          <ScrollShadow className="flex flex-col gap-2 h-[95%]">
            {tmpTweet.map((tweet, index) => (
              <div className="flex gap-3" key={index}>
                <div className="relative flex-none">
                  <Avatar
                    src={account?.data?.profile_pic}
                    classNames={{ base: "w-8 h-8" }}
                  />
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="relative w-full rounded-medium bg-content2 px-4 py-2 text-default-600">
                    <div className="flex flex-col">
                      <div className="flex gap-1 items-center w-full text-tiny font-semibold text-default-foreground">
                        {account?.data?.name}{" "}
                        {account?.data?.is_blue_tick && (
                          <Icon
                            icon="mage:verified-check-fill"
                            className="text-blue-500"
                            width={18}
                          />
                        )}
                      </div>
                      <div className="flex-end text-tiny text-default-400">
                        @{account?.data?.username}
                      </div>
                    </div>
                    <div className="mt-2 text-tiny text-default-600 whitespace-pre-line">
                      {tweet}
                    </div>
                    <Spacer y={3} />
                    <div className="flex justify-between items-center gap-2">
                      <div className="flex gap-1 items-center">
                        <Icon icon="gravity-ui:comment" width={15} />
                        <p className=" text-default-400 text-tiny">
                          {randomNumbersCommentRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="flat-color-icons:like" width={15} />
                        <p className=" text-default-400 text-tiny">
                          {randomNumbersLikeRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="ant-design:retweet-outlined" width={15} />
                        <p className="text-default-400 text-tiny">
                          {randomNumbersRetweetRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="bx:bar-chart" width={15} />
                        <p className="text-default-400 text-tiny">
                          {randomNumbersImpressionRef.current[index]}
                        </p>
                      </div>
                      <div className="flex items-center text-xs font-semibold gap-1">
                        {/* <Icon icon="mdi:text-box" width={20} /> */}
                        <p className="text-default-400 text-tiny">
                          {tweet.length}
                        </p>
                      </div>

                      {/* <p
                        className={`text-end text-xs font-semibold ${
                          tweet.length > 280 && "text-red-500"
                        }`}
                      >
                        {tweet.length}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {mediaPreview.map((preview, index) => (
              <>
                <div className="flex gap-3" key={index}>
                  <div className="relative flex-none">
                    <Avatar
                      src={account?.data?.profile_pic}
                      classNames={{ base: "w-8 h-8" }}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-4">
                    <div className="relative w-full rounded-medium bg-content2 p-2 text-default-600">
                      <div key={index} className="flex flex-col gap-2">
                        {preview.type === "video" ? (
                          <video controls className="w-full h-auto rounded-sm">
                            <source src={preview.url} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <Image
                            alt={`Preview ${index}`}
                            src={preview.url}
                            radius="sm"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </ScrollShadow>
        </CardBody>
        <Divider />
      </Card>
      <div className="px-2">
        {/* <div className="h-full">
        <div className="flex justify-between w-full pr-6 py-4 px-6">
          <div className="text-gray-700 font-semibold">Preview</div>
          <IoClose
            size={25}
            onClick={() => close(false)}
            className="cursor-pointer"
          />
        </div>
        {tmpTweet.map(
          (tweet, index) =>
            tweet && (
              <div className={`flex items-start gap-2.5 mb-4 px-4`}>
                <img
                  className="w-8 h-8 rounded-full"
                  src={account?.data?.profile_pic}
                  alt=""
                />
                <div
                  className={`flex flex-col flex-grow p-4 rounded-e-xl rounded-xl shadow-md border bg-white`}
                >
                  <div className="flex flex-col justify-between mb-2">
                    <div className="flex">
                      <span className="text-sm font-semibold text-gray-900">
                        {account?.data?.name}
                      </span>

                      {account?.data?.is_blue_tick && (
                        <BadgeCheckIcon className="w-5 h-5 ml-1" />
                      )}
                    </div>
                    <div className="text-xs text-gray-400">
                      @{account?.data?.username}
                    </div>
                  </div>
                  <p className="flex whitespace-pre-line break-all justify-start text-sm font-normal py-2.5 text-gray-900">
                    {tweet}
                  </p>
                  <p
                    className={`text-end text-sm font-semibold ${
                      tweet.length > 280 ? "text-red-500" : "text-gray-500"
                    }`}
                  >
                    {tweet.length}
                  </p>
                </div>
              </div>
            )
        )}
      </div> */}
      </div>
    </>
  );
}

export default TweetPreview;
