import { useRef, useEffect, useContext, useState } from "react";
import { ComposeContext } from "../context/compose_tweet_context";
import { AuthContext } from "../context/auth_context";
import "react-toastify/dist/ReactToastify.css";
import {
  ScrollShadow,
  Tab,
  Tabs,
  cn,
  Tooltip,
  Spinner,
} from "@nextui-org/react";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Avatar,
  Image,
} from "@nextui-org/react";

import MessageCard from "./ai_modal/message_card";
import { Icon } from "@iconify/react";

import PromptInput from "./ai_modal/prompt_input";

const AiModal = () => {
  const { account } = useContext(AuthContext);

  const cancelButtonRef = useRef(null);
  const ref = useRef(null);
  const [isFailed, setIsFailed] = useState(false);

  const {
    setTweet,
    aiModal,
    setAiModal,
    aiTweet,
    setAiTweet,
    loading,
    setLoading,
    retry,
    setRetry,
    conversation,
    setConversation,
    setCancel,
    isRegenerate,
    setRegClicked,
  } = useContext(ComposeContext);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (conversation.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [conversation.length, loading]);

  return (
    <>
      <Modal
        isOpen={aiModal}
        onClose={() => {
          setAiTweet("");
          setConversation([]);
          setAiModal(false);
          setLoading(true);
          setCancel(true);
        }}
        size="2xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {loading
                  ? "Your AI assistant is working on it!"
                  : "Your AI assistant response!"}

                <div className="flex items-center gap-1">
                  <Image src="/gpt.png" width={25} alt="GPT-4 Logo" />
                  <span className="text-xs font-medium">Powered by GPT-4</span>
                </div>
              </ModalHeader>
              <ModalBody>
                <ScrollShadow className={cn("flex h-[400px] flex-col")}>
                  <div className="flex flex-col gap-4 px-1">
                    {conversation.map((chat, index) => (
                      <MessageCard
                        key={index}
                        attempts={index === 1 ? 2 : 1}
                        avatar={
                          chat?.role === "assistant"
                            ? "/bot.jpg"
                            : account?.data?.profile_pic
                        }
                        currentAttempt={index === 1 ? 2 : 1}
                        message={
                          chat.role === "assistant" &&
                          !loading &&
                          index === conversation.length - 1
                            ? chat?.content
                            : chat?.content
                        }
                        messageClassName={
                          chat?.role === "user"
                            ? "bg-content3 text-content3-foreground"
                            : ""
                        }
                        showFeedback={chat?.role === "assistant"}
                      />
                    ))}
                    {loading && (
                      <div className={`flex items-start gap-2.5 mb-4`}>
                        <div className="flex flex-col flex-grow ">
                          <Spinner size="sm" />
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollShadow>
                <div className="flex flex-col gap-2 mt-2 mb-2">
                  <div className="flex w-full flex-col gap-4">
                    {isRegenerate && (
                      <div>
                        <Button
                          // isDisabled={isRegenerating}
                          size="sm"
                          startContent={
                            <Icon
                              className={cn("text-medium origin-center")}
                              icon="solar:restart-linear"
                            />
                          }
                          variant="flat"
                          onPress={() => setRegClicked(true)}
                        >
                          Regenerate
                        </Button>
                      </div>
                    )}
                    <form className="flex w-full flex-col items-start rounded-medium bg-default-100 transition-colors hover:bg-default-200/70">
                      <PromptInput
                        classNames={{
                          inputWrapper: "!bg-transparent shadow-none",
                          innerWrapper: "relative",
                          input: "pt-1 pl-2 pb-6 !pr-10",
                        }}
                        endContent={
                          <div className="flex items-end gap-2">
                            <Tooltip showArrow content="Send message">
                              <Button
                                isLoading={loading}
                                isIconOnly
                                color={!aiTweet ? "default" : "primary"}
                                isDisabled={!aiTweet}
                                radius="lg"
                                size="sm"
                                variant="solid"
                                onClick={handleRetry}
                                ref={cancelButtonRef}
                              >
                                <Icon
                                  className={cn(
                                    "[&>path]:stroke-[2px]",
                                    !aiTweet
                                      ? "text-default-600"
                                      : "text-primary-foreground"
                                  )}
                                  icon="solar:arrow-up-linear"
                                  width={20}
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        }
                        minRows={3}
                        radius="lg"
                        value={aiTweet}
                        variant="flat"
                        onValueChange={setAiTweet}
                      />
                    </form>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* <Transition.Root show={aiModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="mr-0 w-full flex items-center justify-end rounded-full">
                    <MdClose
                      className="h-6 w-6 bg-red-100 text-red-700 rounded-md cursor-pointer hover:bg-red-200"
                      aria-hidden="true"
                      onClick={() => {
                        setAiTweet("");
                        setConversation([]);
                        setAiModal(false);
                        setLoading(true);
                        setCancel(true);
                      }}
                    />
                  </div>
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-6"
                      >
                        {loading
                          ? "Your AI assistant is working on it!"
                          : "Your AI assistant response!"}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="h-29 overflow-y-auto">
                          {conversation.map((chat, index) => (
                            <div
                              key={index}
                              className={`flex items-start gap-2.5 mb-4`}
                            >
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  chat?.role === "assistant"
                                    ? "/bot.png"
                                    : account?.data?.profile_pic
                                }
                                alt="Chat Image"
                              />
                              <div
                                className={`flex flex-col flex-grow p-4 rounded-e-xl rounded-xl shadow-md border ${
                                  chat?.role === "user"
                                    ? "bg-white"
                                    : "bg-gray-100 border-gray-200"
                                }`}
                              >
                                <div className="flex items-center justify-between mb-2">
                                  <span className="text-sm font-semibold text-gray-900">
                                    {chat?.role === "user"
                                      ? account?.data?.name
                                      : "Assistant"}
                                  </span>
                                </div>
                                <p className="flex whitespace-pre-line justify-start text-sm font-normal py-2.5 text-gray-900">
                                  {chat.role === "assistant" &&
                                  !loading &&
                                  index === conversation.length - 1
                                    ? // <TypeAnimation
                                      //   sequence={[chat?.content]}
                                      //   wrapper="span"
                                      //   speed={{
                                      //     type: "keyStrokeDelayInMs",
                                      //     value: 10,
                                      //   }}
                                      //   cursor={false}
                                      // />
                                      chat?.content
                                    : chat?.content}
                                </p>

                                {chat?.role === "assistant" && (
                                  <div className="flex items-center justify-end space-x-4 mt-2">
                                    <button
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          chat?.content
                                        );
                                        toast.success("Copied to clipboard!", {
                                          autoClose: 3000,
                                        });
                                      }}
                                      className="text-xs font-medium text-gray-600 hover:text-blue-500 focus:outline-none"
                                    >
                                      Copy
                                    </button>
                                    <button
                                      type="button"
                                      className="justify-center rounded-md bg-indigo-600 px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                      onClick={() => {
                                        setTweet(chat?.content);
                                        setAiTweet("");
                                        setAiModal(false);
                                        setLoading(true);
                                      }}
                                    >
                                      Edit and Use
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          {loading && (
                            <div className={`flex items-start gap-2.5 mb-4`}>
                              <div className="flex flex-col flex-grow ">
                                <p className="flex justify-start text-sm font-normal py-2.5 text-gray-900">
                                  <LoaderDark />
                                </p>
                              </div>
                            </div>
                          )}
                          <div ref={ref} />
                        </div>

                        <div className="relative mt-4">
                          <div className="absolute top-4 left-3">
                            <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
                          </div>
                          <input
                            type="text"
                            className="h-12 w-full p-3 rounded-lg z-0 focus:shadow focus:outline-none border border-gray-200"
                            placeholder="ex: make it shorter"
                            value={aiTweet}
                            onChange={(e) => setAiTweet(e.target.value)}
                            disabled={loading}
                          />
                          <div className="absolute top-1/2 transform -translate-y-1/2 right-2">
                            <button
                              onClick={handleRetry}
                              ref={cancelButtonRef}
                              className="px-3 py-2 text-sm font-semibold rounded-lg bg-white text-gray-900 hover:bg-gray-100"
                              disabled={loading}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default AiModal;
