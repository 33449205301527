import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdOutlineSchedule } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfDay, isToday } from "date-fns";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";

import { Icon } from "@iconify/react";

const timediff = require("timediff");

const TweetConfirmation = ({
  isConfirmation,
  setConfirmation,
  tweet,
  isScheduled,
  confirmTweetNow,
  confirmScheduleTweet,
  scheduledDate,
  setScheduledDate,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const calculateRemainingTime = () => {
    const dif = timediff(new Date(), scheduledDate, {
      units: "YDHm",
      returnZeros: false,
    });

    if (dif) {
      const timeParts = [];

      if (dif.days > 0) {
        timeParts.push(`${dif.days} days`);
      }

      if (dif.hours > 0) {
        timeParts.push(`${dif.hours} hours`);
      }

      if (dif.minutes > 0) {
        timeParts.push(`${dif.minutes} minutes`);
      }

      return `Your tweet is scheduled to be published in approximately ${timeParts.join(
        " "
      )}`;
    }
  };

  const handleDateChange = (date) => {
    setScheduledDate(date);

    // Check if the date includes a time component
    if (date && date.getHours() !== 0) {
      setIsDatePickerOpen(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isConfirmation}
        onOpenChange={(open) => setConfirmation(open)}
        size="lg"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  {isScheduled
                    ? "Schedule Tweet Confirmation"
                    : "Tweet Confirmation"}
                  <Icon
                    icon={isScheduled ? "mdi:clock-outline" : "mdi:check"}
                    className="text-blue-500"
                    width={25}
                  />
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="mt-2 border border-gray-300 rounded-xl p-4">
                  <p className="text-sm text-default-500 whitespace-pre-line h-[200px] overflow-y-auto">
                    {tweet.trim()}
                  </p>
                </div>
                {isScheduled && (
                  <div className="mt-4">
                    <label className="block mb-2 text-sm font-medium text-default-500">
                      Choose Date and Time
                    </label>
                    <div className="relative">
                      <DatePicker
                        selected={scheduledDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        className="p-2 border border-gray-300 rounded-md focus:outline-none w-full text-default-500"
                        onClickOutside={() => setIsDatePickerOpen(false)}
                        onInputClick={() => setIsDatePickerOpen(true)}
                        open={isDatePickerOpen}
                        minDate={new Date()}
                        minTime={
                          isToday(scheduledDate) ? new Date() : undefined
                        }
                        maxTime={
                          isToday(scheduledDate)
                            ? endOfDay(new Date())
                            : undefined
                        }
                      />
                      <div className="absolute right-0 top-0 p-2 cursor-pointer">
                        <MdOutlineSchedule
                          onClick={() => setIsDatePickerOpen(true)}
                          className="w-6 h-6 text-blue-500"
                        />
                      </div>
                    </div>
                    <div className="text-sm text-default-500 mt-4 flex">
                      <div className="mx-auto">{calculateRemainingTime()}</div>
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={onClose}
                  size="sm"
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  onClick={isScheduled ? confirmScheduleTweet : confirmTweetNow}
                  size="sm"
                >
                  {isScheduled ? "Schedule" : "Tweet Now"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {/* <Transition.Root show={isConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={setConfirmation}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-white rounded-lg w-full max-w-sm p-6 mx-auto mt-10">
                <div className="flex items-center justify-center mb-4">
                  <span
                    className={`text-${isScheduled ? "blue" : "indigo"}-500`}
                  >
                    {isScheduled ? (
                      <MdOutlineSchedule className="w-6 h-6" />
                    ) : (
                      <FaCheck className="w-6 h-6" />
                    )}
                  </span>
                  <Dialog.Title className="ml-2 text-base font-semibold leading-6 text-gray-900">
                    {isScheduled
                      ? "Schedule Tweet Confirmation"
                      : "Tweet Confirmation"}
                  </Dialog.Title>
                </div>

                <div className="mt-2 border border-gray-300 rounded-xl p-4">
                  <p className="text-sm text-gray-700 whitespace-pre-line">
                    {tweet.trim()}
                  </p>
                </div>

                {isScheduled && (
                  <div className="mt-4">
                    <label className="block mb-2 text-sm font-medium text-gray-800">
                      Choose Date and Time
                    </label>
                    <div className="relative">
                      <DatePicker
                        selected={scheduledDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 w-full text-gray-800"
                        onClickOutside={() => setIsDatePickerOpen(false)}
                        onInputClick={() => setIsDatePickerOpen(true)}
                        open={isDatePickerOpen}
                        minDate={new Date()}
                        minTime={
                          isToday(scheduledDate) ? new Date() : undefined
                        }
                        maxTime={
                          isToday(scheduledDate)
                            ? endOfDay(new Date())
                            : undefined
                        }
                      />
                      <div className="absolute right-0 top-0 p-2 cursor-pointer">
                        <MdOutlineSchedule
                          onClick={() => setIsDatePickerOpen(true)}
                          className="w-6 h-6 text-blue-500"
                        />
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 mt-4 flex">
                      <div className="mx-auto">{calculateRemainingTime()}</div>
                    </div>
                  </div>
                )}

                <div className="mt-6 flex justify-between">
                  <button
                    type="button"
                    className={`inline-flex w-1/2 justify-center rounded-md border border-gray-600" px-3 py-2 text-sm font-semibold text-gray-600 mr-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
                    onClick={() => setConfirmation(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-1/2 justify-center rounded-md bg-${
                      isScheduled ? "blue" : "indigo"
                    }-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${
                      isScheduled ? "blue" : "indigo"
                    }-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${
                      isScheduled ? "blue" : "indigo"
                    }-600`}
                    onClick={
                      isScheduled ? confirmScheduleTweet : confirmTweetNow
                    }
                  >
                    {isScheduled ? "Schedule" : "Tweet Now"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default TweetConfirmation;
